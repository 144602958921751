import mbp from './mbp.png';
import Logo from './logo.png';
import { useState, useCallback } from 'react';
import './App.css';


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const numbersPerColumn = 15;
const dimension = 5;
const freebieIndex = Math.floor(dimension * dimension / 2);

// Generate 25 Bingo values
function generateValues() {
  const values = [...Array(dimension).keys()].map((_) => [...Array(numbersPerColumn).keys()]);
  // Shuffle the indices of each column
  values.forEach(col => shuffleArray(col));
  // Pick the first 5, and add its column offset
  const selected = values.map((c, i) => c.filter((_, j) => j < dimension).map(e => e + 1 + (i * numbersPerColumn)));

  // Flatten the 2d array
  const output = [];
  for (let i = 0; i < dimension; i++) {
    selected.forEach(e => output.push(e[i]));
  }
  return output;
}

function App() {
  const [cells, setCells] = useState(generateValues());
  const [selected, setSelected] = useState([freebieIndex]);

  const click = useCallback((i) => {
    if (i === freebieIndex) return;
    setSelected(e => e.indexOf(i) >= 0 ? e.filter(n => n !== i) : e.concat(i));
  }, [setSelected]);

  const resetSelected = useCallback(() => setSelected([freebieIndex]), [setSelected]);
  const resetBoard = useCallback(() => {
    if (window.confirm('Are you sure you want to reset the board? This action is not reversible.')) {
      setCells(generateValues());
      resetSelected();
    }
  }, [setCells, resetSelected]);

  return (<>
    <div className="container">
      <div className="row header">
        <div className="col text-center">
          <img src={Logo} alt="myBingo" className="header-logo" />
        </div>
      </div>
      <div className="row header">
        <div className="col text-center">
          <button type="button" className="btn btn-secondary" onClick={resetBoard}>Reset Board</button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="bingo-grid">
            <div className="bingo-cell bingo-header">B</div>
            <div className="bingo-cell bingo-header">I</div>
            <div className="bingo-cell bingo-header">N</div>
            <div className="bingo-cell bingo-header">G</div>
            <div className="bingo-cell bingo-header">O</div>
            {cells && cells.map((c, i) => <button type="button" key={i} className={`bingo-cell ${selected.indexOf(i) >= 0 ? 'bingo-cell--selected' : ''}`} onClick={() => click(i)}>
              {i === freebieIndex ? <img className="bingo-free" src={mbp} title="Freebie!" alt="Freebie!" /> : c}
            </button>)}
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default App;
